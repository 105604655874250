import styled from "styled-components"
import * as React from 'react';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';

import IconButton from '@mui/material/IconButton';
import { MdOutlinePrint as PrintIcon } from "react-icons/md"
import axios from "axios";
import warningMessage from "../../helpers/warnMessage";

import formatMonetaryValue from "../../helpers/formatMonetaryValue"



import Fab from '@mui/material/Fab';
import Swal from "sweetalert2";
import ChangeStockForm from "./ChangeStockForm";
import AddItemForm from "./AddItemForm";
import { useState } from "react";
import { Checkbox, TextField } from "@mui/material";
import { Box, width } from "@mui/system";
import FinishOrderForm from "./FinishOrderForm";
import { AuthContext } from "../../context/AuthContext";
import Loader from "../../Components/SideMenu/Loader";
import usePrintOrder from "../../hooks/usePrintOrders";
 


export default function OrderPage() {
    const [searchedItemName, setSearchedItemName] = React.useState("")
    const [stock, setStock] = React.useState([])
    const [selectedItems, setSelectedItems] = useState([])
    const [selectedItemChangingstock, setselectedItemChangingStock] = React.useState(null);
    const [IsSavingOrder, setIsSavingOrder] = React.useState(false)
    const [isStockLoading, setIsStockLoading] = useState(true)
    const { user } = React.useContext(AuthContext)
    const {printOrders} = usePrintOrder()
 
    const [IsSavingOrderLoading, setIsSavingOrderLoading] = React.useState(false)

    function removeDiacritics(str) {
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    }

    const filteredStock = searchedItemName === "" ? stock : stock.filter(s =>
        removeDiacritics(s.name.toLowerCase())
            .includes(removeDiacritics(searchedItemName.toLowerCase()))
    );

    function mapOrderForPrinting(order, shouldPrintSeparate) {

        if (shouldPrintSeparate === null) 
      {  order.shouldPrint = !order.shouldPrint;
        order.shouldPrintSeparate = false}
        else
         {
            order.shouldPrintSeparate = order.shouldPrint && !shouldPrintSeparate
            order.shouldPrintSeparate && warningMessage(`As fichas de ${order.name} serão impresas separadas`)
        }

        setSelectedItems([...selectedItems])
    }

    const ordersForPrinting = []
    for (const order of selectedItems) {
        if (!order.shouldPrint)
            continue

        if (!order.shouldPrintSeparate) {
            ordersForPrinting.push(order)
            continue
        }

        for (let i = 0; i < order.selectedAmount; i++) {
            ordersForPrinting.push({ ...order, selectedAmount: 1 })
        }
    }

    function printSelectedOrders() {
        if (ordersForPrinting.length === 0)
            return warningMessage("Nenhum produto selecionado")

            printOrders(ordersForPrinting)
    }


    const totalOrdersPrice = selectedItems.reduce(
        (accumulator, currentOrder) => accumulator + (currentOrder.selectedAmount * currentOrder.price),
        0
    );


    selectedItems.forEach(s => {
        const stockItem = stock.find(i => i.id === s.id);
        if (s.selectedAmount > stockItem.stockAmount) {
            warningMessage(`Só há ${stockItem.stockAmount} ${stockItem.name} disponíveis`)
            s.selectedAmount = stockItem.stockAmount;
        }
    })


    React.useEffect(() => {
        getCurrentStock()
        const intervalId = setInterval(() => getCurrentStock(intervalId), 2000)

        return () => clearInterval(intervalId);
    }, [])



    return (
        <>
            <Cover onClick={() => { if (!IsSavingOrderLoading) setIsSavingOrder(false) }} showModal={IsSavingOrder}>

                {<FinishOrderForm closeModal={() => setIsSavingOrder(false)} IsSavingOrderLoading={IsSavingOrderLoading} setIsSavingOrderLoading={setIsSavingOrderLoading} updateStock={getCurrentStock} totalValue={totalOrdersPrice * 100} selectedItems={selectedItems} resetSelectedItems={() => setSelectedItems([])} />}
            </Cover>
            <Container>
                <div>
                    <Paper
                        component="form"
                        sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400, marginLeft: "5rem" }}
                    >

                        <InputBase
                            sx={{ ml: 1, flex: 1 }}
                            placeholder="Buscar"
                            value={searchedItemName}
                            onChange={e => setSearchedItemName(e.target.value)}
                            onKeyDown={(e) => { e.key === "Enter" && e.preventDefault() }}
                            sear

                        />
                        <IconButton type="button" sx={{ p: '10px' }} onClick={() => setSearchedItemName("")} aria-label="search">
                            x
                        </IconButton>


                    </Paper>

                    <Box sx={{ display: 'flex', alignItems: 'flex-end', marginLeft: "10rem", border: "1px dashed red", padding: "1rem", borderRadius: "9px" }}>
                        <p style={{ fontSize: "1.5rem", marginRight: "1rem" }}>Total</p>

                        <p style={{ fontSize: "1.5rem", marginRight: "1rem" }}>
                            {`R$ ${(formatMonetaryValue((totalOrdersPrice * 100).toFixed(2)))}`}


                        </p>
                    </Box>
                    <Fab variant="extended" sx={{ fontSize: "1rem", borderRadius: "15px", marginLeft: "2rem" }} onClick={() => setIsSavingOrder(true)}  >
                        {<> Concluir </>}
                    </Fab>
                </div>

                <div className="holder">
                    <StockList>
                        <ListItem >
                            <div>

                                <p >Nome</p>
                                <p>Preço</p>
                                <p>Qtd</p>
                            </div>


                        </ListItem>
                        {isStockLoading ? <LoaderContainer> <Loader width={"8rem"} height={"8rem"} /></LoaderContainer> : filteredStock?.map(s => <ListItem key={s.id} onClick={() => {
                            if (s.stockAmount === 0)
                                return warningMessage(`Não há ${s.name} disponível`)

                            const selectedItemIndex = selectedItems.findIndex(si => si.id === s.id)
                            if (selectedItemIndex >= 0)
                                setSelectedItems(selectedItems.filter((s, i) => i !== selectedItemIndex))
                            else
                                setSelectedItems([...selectedItems, { ...s, selectedAmount: 1, shouldPrint: true }])
                        }}>
                            <div>

                                <p style={{ width: "4rem" }}>{s.name}</p>
                                <p> R$ {formatMonetaryValue(s.price * 100)}</p>
                                <p>{s.stockAmount || 0}</p>
                            </div>
                            <div>

                                <Checkbox checked={selectedItems.findIndex(si => si.id === s.id) >= 0} ></Checkbox>
                            </div>
                        </ListItem>)}
                    </StockList>

                    <StockList right>
                        <ListItem right>
                            <h1>Pedido</h1>
                            <Fab variant="extended" sx={{ fontSize: "1.5rem", height: "2rem", marginRight: "1rem" }} onClick={printSelectedOrders} >
                                {<PrintIcon size={"2rem"} />}
                            </Fab>
                        </ListItem >
                        {selectedItems?.map(s => <ListItem key={s.id} right>
                            <div >

                                <p style={{ width: "4rem" }}>{s.name}</p>
                                <p > R$ {formatMonetaryValue(s.price * 100)}</p>
                                <div style={{ width: "9rem", display: "flex", alignItems: "center", justifyContent: "space-between", fontSize: "1.5rem" }}>
                                    <Fab variant="extended" sx={{ backgroundColor: "#f00000ab", fontSize: "1.5rem", height: "2rem" }} onClick={() => removeItemUnit(s)}  >
                                        {<> - </>}
                                    </Fab>
                                    <input onClick={()=>warningMessage(`Atenção: Você está selecionando ${s.name} manualmente`)} style={{minWidth:"3rem", border:"none", fontSize:"1rem", textAlign:"center", fontWeight:"bold"}} onChange={e => {s.selectedAmount = +e.target.value; setSelectedItems([...selectedItems])}} value={s.selectedAmount < 10 ? `0${s.selectedAmount || 0}` :  s.selectedAmount || 0}></input>
                                    <Fab variant="extended" sx={{ backgroundColor: "#00f000ab", fontSize: "1.5rem", height: "2rem" }} onClick={() => { s.selectedAmount < s.stockAmount ? s.selectedAmount = s.selectedAmount + 1 : warningMessage("Limite do estoque atingido"); setSelectedItems([...selectedItems]) }} >
                                        {<> + </>}
                                    </Fab>
                                    <Checkbox onContextMenu={e => { e.preventDefault(); mapOrderForPrinting(s, !!s.shouldPrintSeparate) }} checked={!!s.shouldPrint} color={s.shouldPrintSeparate ? "warning" : "default"} onClick={() => { mapOrderForPrinting(s, null) }} style={{ marginLeft: ".25rem" }}></Checkbox>

                                </div>
                            </div>
                            <div style={{ width: "0px", }}>


                            </div>
                        </ListItem>)}
                    </StockList>
                </div>
            </Container>
        </>
    )

    function removeItemUnit(item) {
        if (item.selectedAmount === 1)
            return setSelectedItems(selectedItems.filter(s => s.id !== item.id))


        item.selectedAmount = item.selectedAmount - 1
        setSelectedItems([...selectedItems])
    }

    function getCurrentStock() {

        const promise = axios.get(`https://${user.ip}/api/products`, { headers: { Authorization: `Bearer ${user.token}` } })
        promise.then(res => {
            setStock(res.data);
            setIsStockLoading(false)
        })
            .catch(err => {
                const errstatus = err?.response?.status
                if (errstatus === 401)
                    warningMessage("Acesso não autorizado")

                if (!errstatus)
                    warningMessage("falha de conexão com o servidor")

                if (!!errstatus && errstatus !== 401)
                    warningMessage("erro inesperado")

                setStock([])
                setIsStockLoading(false)


            })
    }



}


const Container = styled.div`
display: flex;
flex-direction: column;
position: relative;
 
align-items: start;

 

min-width: 910px;

.holder{
    display: flex;
    width: 70vw;
}

button{

    z-index: 2;
}

& > div:first-child{
    display: flex;
    align-items: center;
    justify-content: center;

    width: 70vw;
    padding-top: 2rem;

}
`

const StockList = styled.ul`
width: 30vw;
height: 70vh;
min-width: 600px;
margin-top: 5rem;
overflow: hidden;
overflow-y: scroll;

     ::-webkit-scrollbar{
        display: none;
    
    }



margin-left: ${({ right }) => right ? "2rem" : "2vw"};
border-radius: 9px;
box-shadow: 5px 5px 10px  #ababab;

padding-top: 4rem;
padding-bottom: 1rem;
 

background-color: white;

`

const ListItem = styled.li`
display: flex;
align-items: center;
justify-content: space-between;


 

& > div:first-child{
display: flex;
width: ${({ right }) => right ? "100%" : "80%"};

height:3.5rem;
align-items: center;
justify-content: space-between;

 
padding: 1rem;
 
p{
    text-align: center;
}
p:nth-child(1){
   
    min-width: 13rem;
 
     
    overflow: hidden;
 
   }
p:nth-child(0){
   min-width: 3rem;
 
}}

p:nth-child(3){
   min-width: 3rem;
}


border-bottom: 1px solid rgba(0,0,0,.1);

&:hover{
    background-color: rgba(240,240,240);
    cursor: pointer;
}

& > div:last-child{
    width: ${({ right }) => right ? "0px !important" : "6rem"};
    margin-right: 2rem;
}

&:first-child{
    position: absolute;
    top: 10rem;
    left: ${({ right }) => right ? "calc(2vw + 632px)" : "2vw"};
    
    background-color: white;
    z-index: 3;

    h1{
        font-size: 1.5rem;
        font-weight: bold;

        line-height: 3.5rem;
        text-align: center;
        width: 100%;
        height: 3.5rem;
        align-items: center;
    }
    
box-shadow: 1px 2px 2px rgba(0,0,0,.1);

    width: 30vw;
    min-width: 600px;

    & > div{
        width: 80%;
        p{
            text-align: center;
            font-weight: bold;
        }
    }
}

 



`

const Cover = styled.div`
display:  ${({ showModal }) => showModal ? "block" : "none"} ;
width: 100vw;
height: 100vh;

position: fixed;
left: 0;
top: 0;

z-index: 10;
background-color: rgba(0,0,0,.4);
`

const LoaderContainer = styled.div`
 

width: 15rem;
height: 50vh;

display: flex;
justify-content: center;
align-items: center;


margin: auto;

`